import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { SessionService } from '../services';
import {
    getIsShortProfile,
    isError,
    isInitialized,
} from '../store/modules/profile';

// если использовать Router.push() а затем browser history API (<- ->)
// будет спровоцирована ошибка connected-next-router (легаси код, быстро решить проблему не получилось)
// поэтому в данном случае используется window.location.href
const doRedirect = redirect => {
    if (typeof window !== 'undefined') {
        window.location.href = redirect;
    }
};

const actions = {
    redirect: ({ redirect }) => {
        doRedirect(redirect);
    },
    redirectFullProfiles: ({ redirect, isShortProfile }) => {
        if (!isShortProfile) {
            doRedirect(redirect);
        }
    },
    redirectShortProfiles: ({ redirect, isShortProfile }) => {
        if (isShortProfile) {
            doRedirect(redirect);
        }
    },
};

export function useRedirectAuthorizedUsers({
    action = 'redirect',
    redirect = '/',
} = {}) {
    const isDisabled = useRef(false);

    const isProfileInitialized = useSelector(isInitialized);
    const isShortProfile = useSelector(getIsShortProfile);
    const isErrorProfile = useSelector(isError);

    useEffect(() => {
        if (!isDisabled.current && !SessionService.isAuth()) {
            isDisabled.current = true;
        }

        if (
            !isDisabled.current &&
            !isErrorProfile &&
            isProfileInitialized &&
            SessionService.isAuth()
        ) {
            if (actions[action]) {
                actions[action]({
                    isShortProfile,
                    redirect,
                });
            }
        }
    }, [
        action,
        redirect,
        isShortProfile,
        isProfileInitialized,
        isErrorProfile,
        isDisabled,
    ]);
}
